import React from "react";
import { Navbar } from "../../tailwind";
import { Footer } from "../../tailwind/Footer";

export default () => (
  <>
    <Navbar/>
    <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8">
      <div className="bg-white rounded border shadow mt-10 p-6">
        <h1 className="text-2xl text-gray-800 font-semibold py-1">Privacy Policy</h1>
        <hr className="py-1"/>
        <p className="text-gray-700 text-sm">
          This privacy policy (“Policy”) describes how Bluefin Enterprises Inc. and its related
          companies (“Company”) collect, use and share personal information of consumer users of this website,
          www.rateinsider.com (the “Site”). This Policy also applies to any of our other websites that post this Policy.
          This
          Policy does not apply to websites that post different statements.
        </p>


        <h2 className="text-lg text-gray-800 font-semibold py-2">WHAT WE COLLECT</h2>
        <p className="text-gray-700 text-sm">We get information about you in a range of ways.</p>

        <ul className="list-disc list-inside">
          <li className="text-gray-700 text-sm">Information You Give Us. We collect your‎ name, postal address, email address, phone number, fax number,
            demographic information (such as your gender and occupation) as well as other information you directly give us
            on our Site.
          </li>
          <li className="text-gray-700 text-sm">Information We Get From Others. We may get information about you from other sources. We may add this to
            information we get from this Site.
          </li>
          <li className="text-gray-700 text-sm">Information Automatically Collected. We automatically log information about you and your computer. For
            example, when visiting our Site, we log your computer operating system type, browser type, browser language, the
            website you visited before browsing to our Site, pages you viewed, how long you spent on a page, access times
            and information about your use of and actions on our Site.
          </li>
          <li className="text-gray-700 text-sm">Cookies. We may log information using 'cookies.' Cookies are small data files stored on your hard drive by a
            website. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies
            (which stay on your computer until you delete them) to provide you with a more personal and interactive
            experience on our Site. This type of information is collected to make the Site more useful to you and to tailor
            the experience with us to meet your special interests and needs.
          </li>
        </ul>

        <h2 className="text-lg text-gray-800 font-semibold py-2">USE OF PERSONAL INFORMATION</h2>
        <p>We use your personal information as follows:</p>

        <ul className="list-disc list-inside">
          <li className="text-gray-700 text-sm">We use your personal information to operate, maintain, and improve our sites, products, and services.</li>
          <li className="text-gray-700 text-sm">We use your personal information to communicate about promotions, upcoming events, and other news about
            products and services offered by us and our selected partners.
          </li>
          <li className="text-gray-700 text-sm">We use your personal information to provide and deliver products and services customers request.</li>
        </ul>


        <h2 className="text-lg text-gray-800 font-semibold py-2">SHARING OF PERSONAL INFORMATION</h2>
        <p>We may share personal information as follows:</p>

        <ul className="list-disc list-inside">
          <li className="text-gray-700 text-sm">We may share personal information with your consent. For example, you may let us share personal information
            with others for their own marketing uses. Those uses will be subject to their privacy policies.
          </li>
          <li className="text-gray-700 text-sm">We may share personal information when we do a business deal, or negotiate a business deal, involving the sale
            or transfer of all or a part of our business or assets. These deals can include any merger, financing,
            acquisition, or bankruptcy transaction or proceeding.
          </li>
          <li className="text-gray-700 text-sm">We may share personal information for legal, protection, and safety purposes.</li>
          <ul className="list-disc list-inside">
            <li className="text-gray-700 text-sm">We may share information to comply with laws.</li>
            <li className="text-gray-700 text-sm">We may share information to respond to lawful requests and legal processes.</li>
            <li className="text-gray-700 text-sm">We may share information to protect the rights and property of RateInsider.com., our agents, customers, and
              others. This includes enforcing our agreements, policies, and terms of use.
            </li>
            <li className="text-gray-700 text-sm">We may share information in an emergency. This includes protecting the safety of our employees and agents,
              our customers, or any person.
            </li>
          </ul>
          <li className="text-gray-700 text-sm">We may share information with those who need it to do work for us.</li>
        </ul>

        <p className="text-gray-700 text-sm">
          We may also share aggregated and/or anonymized data with others for their own uses.
        </p>
        <h2 className="text-lg text-gray-800 font-semibold py-1">INFORMATION CHOICES
          AND CHANGES</h2>
        <p className="text-gray-700 text-sm">Our marketing emails tell you how to “opt-out.” If you opt out, we may still send you
          non-marketing emails. Non-marketing emails include emails about your accounts and our business dealings with
          you.</p>
        <p className="text-gray-700 text-sm">You may send requests about personal information to our Contact Information below. You can request to
          change contact choices, opt-out of our sharing with others, and update your personal information.</p>
        <p className="text-gray-700 text-sm">You can
          typically remove and reject cookies from our Site with your browser settings. Many browsers are set to accept
          cookies until you change your settings. If you remove or reject our cookies, it could affect how our Site works
          for
          you.</p>



        <h2 className="text-lg text-gray-800 font-semibold py-2">CONTACT INFORMATION.</h2>
        <p className="text-gray-700 text-sm">We welcome your comments or questions about this privacy policy. You may
          also contact us at our address:</p>
        <p className="text-gray-700 text-sm">
          Bluefin Enterprises Inc.
        </p>
        <p className="text-gray-700 text-sm">
          2050 River Road West
        </p>
        <p className="text-gray-700 text-sm">Wasaga Beach, Ontario, L9Z 2W1, Canada</p>

        <p className="text-gray-700 text-sm">sales@bluefinenterprises.com</p>
        <p className="text-gray-700 text-sm">(844) 334-1515</p>

        <h2 className="text-lg text-gray-800 font-semibold py-2">CHANGES TO THIS PRIVACY POLICY.</h2><p className="text-gray-700 text-sm">This privacy
        policy
        was Last Updated on January 30, 2015.</p><p className="text-gray-700 text-sm">We may change this privacy policy. If we make any changes, we will
        change the Last Updated date above.</p>
      </div>
    </div>
    <Footer/>
  </>
)